//FILE DI PROVA DOVE HO MESSO SOFIA E SOFIA 3D INSIEME

console.log("Caricamento Sofia");

import * as THREE from "./build/three.module.js";

import { OrbitControls } from "./jsm/controls/OrbitControls.js";
import { GLTFLoader } from "./jsm/loaders/GLTFLoader.js";
//import { RoughnessMipmapper } from './jsm/utils/RoughnessMipmapper.js';

// import "https://code.responsivevoice.org/responsivevoice.js?key=jhjNKrgz"
// import "https://code.responsivevoice.org/responsivevoice.js"

const DEFAULT_ZOOM = 6; //6 originariamente

var idleAnimation, speackAnimation;
var okAnimation = [];
var noAnimation = [];

var defaultZoom;
var camera;
var idleThreadId = 0;
//var pulseThreadId = 0;
let scene, renderer, stats, object;
var currentAction = null;
var animationBar;
var spinLoader;
var loadingManager;

var camera;
var defaultZoom;
var idleThreadId = 0;
var pulseThreadId = 0;

var nextAnimation = null;
var currentAnimation;

var w, h;

const clock = new THREE.Clock();

let mixer;

var waitingTextArray;
var waitingTextIndex = 0;
var threadId;

const IDLE_THREAD_TIMEOUT = 10000;

const path = 'https://d5okykjv090ma.cloudfront.net/sofia/sofia.glb';

const IDLE = 1;
const SPEACK = 0;

const OK_1 = 2;
const OK_2 = 3;
const OK_3 = 5;

const NO_1 = 4;
const NO_2 = 6;
const NO_3 = 7;
const NO_4 = 8;
const NO_5 = 9;



function audioStarts()
{
 		console.log("audioStarts");
				
// 		//setTimeout(function() { commentato di base
 			idleAnimation.stop();
 			speackAnimation.play();
 			console.log("speackAnimation");
//}, 500); commentato di base
};

function audioEnds()
{
 	console.log("audioEnds");
			
	speackAnimation.stop();
	
	nextAnimation.loop = THREE.LoopOnce;
		
 	nextAnimation.play();
		
 	setTimeout(function() {
 		nextAnimation.stop();
 		idleAnimation.play();
 	}, nextAnimation.getClip().duration * 1000);

		
 	//startIdleThread(); commentato di base

 	$('#chat-submit').removeAttr("disabled");
 		/*commentato di base
 		var n = getRandomInt(2);
		
 		if(n == 1) 		
 			currentAnimation = endAnswerAnimation;
 		else
 			currentAnimation  = startAnimation;

 		currentAnimation.play();
 
 		setTimeout(function() {
 			currentAnimation.stop();
 			idleAnimation.play();
 		}, 2600);
		commentato di base*/
};

export function play(text) 
{
 	if(text.includes("fai domande che riguardano") || text.includes("Purtroppo")) 
 	{
 		var n = getRandomInt(noAnimation.length);
 		nextAnimation = noAnimation[n];
 	}			
 	else
 	{
 		var n = getRandomInt(okAnimation.length);
 		nextAnimation = okAnimation[n];
 	}
	
 	var query = "https://65.21.48.175/alycia/api/say?text=" + encodeURIComponent(text); // var query = "https://sofia.dises.ai/alycia/api/say?text=" + encodeURIComponent(text);
 	var container = document.getElementById("audio-container");
 	container.innerHTML = '<audio src="' + query + '" onplay="audioStarts()" onended="audioEnds()" autoplay></audio>';
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

console.log("Fine Sofia");


//INIZIO SOFIA 3D


console.log("Caricamento Sofia3d");


init();
animate();
loadObject();

function loadObject() {

	// model
	console.log("Inizio LOAD");

	const gltfLoader = new GLTFLoader();
	console.log("load model");

	gltfLoader.load(path, (gltf) => {
		console.log("model loaded");

		object = gltf.scene;

		mixer = new THREE.AnimationMixer(object);

		idleAnimation = mixer.clipAction(gltf.animations[IDLE]);
		console.log("sto per fare il playAnimationIdle");
		idleAnimation.play();
		console.log("ho fatto il playAnimationIdle");

		speackAnimation = mixer.clipAction(gltf.animations[SPEACK]);

		okAnimation.push(mixer.clipAction(gltf.animations[OK_1]));
		okAnimation.push(mixer.clipAction(gltf.animations[OK_2]));
		okAnimation.push(mixer.clipAction(gltf.animations[OK_3]));

		noAnimation.push(mixer.clipAction(gltf.animations[NO_1]));
		noAnimation.push(mixer.clipAction(gltf.animations[NO_2]));
		noAnimation.push(mixer.clipAction(gltf.animations[NO_3]));
		noAnimation.push(mixer.clipAction(gltf.animations[NO_4]));
		noAnimation.push(mixer.clipAction(gltf.animations[NO_5]));


		object.traverse(function (child) {

			if (child.isMesh) {

				child.castShadow = true;
				child.receiveShadow = true;

			}

		});

		scene.add(object);

		faceCamera(object);

	},
	function (xhr) {
		console.log((xhr.loaded / xhr.total * 100) + '% loaded');
		$('.waiting-text').text('' + Math.floor(xhr.loaded / xhr.total * 100) + '%');
	
		if (xhr.loaded >= xhr.total) {
			const loadingScreen = document.getElementById('loading-screen');
			loadingScreen.classList.add('fade-out');
			setTimeout(function () {
				$(loadingScreen).hide();
				showWelcomeButton();
			}, 500);
		}
	},
	function (error) {
		console.log('An error happened');
		console.log(error);
	});

	//responsiveVoice.speak('fine load', "Italian Female", { rate: 1 });
	
	console.log("Fine LOAD");
}

function showWelcomeButton() {
    const button = document.createElement('button');
    button.id = 'welcome-button';
    button.innerText = 'Ciao, clicca qui per ascoltare il mio\nmessaggio di benvenuto!';
    
    // Trova la colonna di sinistra e aggiungi il pulsante
    const leftColumn = document.querySelector('.left-column');
    leftColumn.insertBefore(button, leftColumn.firstChildChild);
    
    button.addEventListener('click', function() {
        playText('Ciao, sono Sofia, l\'assistente virtuale del dises. Come posso aiutarti?');
        button.remove();  // Rimuove il pulsante dopo il clic
    });
}

export function playText(testo) {

	responsiveVoice.speak(testo, "Italian Female", { rate: 1.2 });

}

// Funzione per chiudere il popup
function closePopup() {
    const overlay = document.getElementById('feedback-popup-overlay');
    const popup = document.getElementById('feedback-popup');

    if (overlay && popup) {
        overlay.style.display = 'none';
        popup.style.display = 'none';
    }
}

function init() {

	console.log("Inizio INIT");

	// Mostra il popup dopo 2 minuti
	setTimeout(() => {
		const overlay = document.getElementById('feedback-popup-overlay');
		const popup = document.getElementById('feedback-popup');

		if (overlay && popup) {
			overlay.style.display = 'block';
			popup.style.display = 'block';
		}
	}, 120000); // 120.000 ms = 2 minuti   //900000); // 900.000 ms = 15 minuti

	// Gestione pulsanti
	const yesButton = document.getElementById('yes-button');
	const notNowButton = document.getElementById('not-now-button');

	if (yesButton && notNowButton) {
		yesButton.addEventListener('click', () => {
			alert('Grazie per il tuo feedback!');
			closePopup();
			//window.location.href = 'https://example.com'; // Navigazione al link fittizio
		});

		notNowButton.addEventListener('click', closePopup);
	}
	//INSERIMENTO VERIFICA COOKIE PER PERMETTERE AL BROWSER DI FAR PARTIRE IL SINTETIZZATORE

	// Funzione per verificare e impostare i cookie con SameSite=None e Secure
	function checkAndSetCookies() {
		// Ottiene tutti i cookie in un'unica stringa e li separa in un array
		const cookies = document.cookie.split(";");

		// Cicla attraverso ogni cookie trovato
		cookies.forEach(cookie => {
			// Divide ogni cookie in nome e valore
			const [name, value] = cookie.split("=");
			
			// Mostra il nome e il valore del cookie nella console (utile per debugging)
			console.log(`Cookie: ${name.trim()}, Valore: ${value}`);
			
			// Verifica che il cookie abbia sia un nome sia un valore prima di procedere
			if (name && value) {
				// Reimposta il cookie con gli attributi SameSite=None e Secure per garantire compatibilità cross-site
				document.cookie = `${name.trim()}=${value}; SameSite=None; Secure; path=/`;
			}
		});
	}

	// Esegue la funzione per controllare e aggiornare i cookie all'avvio
	checkAndSetCookies();

	/*questa funzione garantisce che i cookie vengano inviati correttamente con le richieste verso il servizio di 
	sintesi vocale di terze parti, assicurando che il servizio funzioni come previsto, soprattutto se si tratta di 
	richieste cross-origin.
	*/
	
	//FINE COOKIE CHECK

	waitingTextArray = $('.waiting-text');

	const container = document.getElementById('center-text');
	console.log(container);
	if (container) {
		console.log("Container defined");
	}
	else {
		console.log("Container is not defined");
	} 
	
	w = $(container).width();
	h = $(container).height();
	console.log("The value of w is: " + w);
	console.log("The value of h is: " + h);

	camera = new THREE.PerspectiveCamera(DEFAULT_ZOOM, w / h, 1, 1000);
	camera.position.set(100, 20, 300);

	scene = new THREE.Scene();


	loadingManager = new THREE.LoadingManager(() => {

		const loadingScreen = document.getElementById('loading-screen');
		loadingScreen.classList.add('fade-out');

		// optional: remove loader from DOM via event listener
		loadingScreen.addEventListener('transitionend', onTransitionEnd);
	});


	const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
	hemiLight.position.set(0, 200, 0);
	scene.add(hemiLight);

	const dirLight = new THREE.DirectionalLight(0xffffff);
	dirLight.position.set(0, 0, 100);
	dirLight.castShadow = true;
	dirLight.shadow.camera.top = 180;
	dirLight.shadow.camera.bottom = - 100;
	dirLight.shadow.camera.left = - 120;
	dirLight.shadow.camera.right = 120;
	scene.add(dirLight);

	renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
	//renderer = new THREE.WebGLRenderer({ antialias: true }); //ORIGINALE
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(w, h);
	renderer.shadowMap.enabled = true;
	
	console.log('sto per stampare renderer');
	console.log(renderer.domElement);
	
	container.appendChild(renderer.domElement);
	
	const controls = new OrbitControls(camera, renderer.domElement);
	controls.minDistance = 2;
	controls.maxDistance = 20;
	controls.target.set(0, -0.4, 0);

	//controls.target.set( 0, 100, 0 );
	controls.update();

	// you can easily disable it by using
	controls.enabled = false;

	window.addEventListener('resize', onWindowResize);

	console.log("Fine INIT");
}

function playAnimation(animationIndex) {
	const animation = mixer.clipAction(object.animations[animationIndex]);
	animation.play();
}


function faceCamera(obj) {
	//obj.rotation.x = -Math.PI / 2;
	//obj.rotation.z = Math.PI / 8;
	obj.rotation.y = Math.PI / 8;
	//obj.position.z = -1000;

	renderer.render(scene, camera);
}

function onWindowResize() {

	camera.aspect = w / h;
	camera.updateProjectionMatrix();

	renderer.setSize(w, h);
}

function animate() {

	requestAnimationFrame(animate);

	const delta = clock.getDelta();
	
	if (mixer) mixer.update(delta);

	renderer.render(scene, camera);
	
}

function onTransitionEnd(event) {

	event.target.remove();
	clearTimeout(threadId);

	for (var i = 0; i < waitingTextArray.length; i++) {
		var nextWaitingText = waitingTextArray[waitingTextIndex];
		$(nextWaitingText).hide();
	}
}