import React from 'react';
import styles from './Layout.module.css';  // Assuming the same CSS file is used for styling

interface DialogProps {
    show: boolean;
    onClose: () => void;
}

const Dialog: React.FC<DialogProps> = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className={styles.dialogOverlay}>
            <div className={styles.dialog}>
                <p>Sofia è una giovane ragazza di circa 22 anni.
                    Il suo aspetto fisico e la sua corporatura sono definiti nelle illustrazioni realizzate per i cartoon in cui è comparsa per la prima volta come main character.<br />
                    Vive a Napoli, a Piazza S. Domenico Maggiore, nel centro antico della città . La sua è una famiglia borghese come tante. Il padre è impiegato al Comune di Napoli, a Piazza Municipio; la madre è assistente sanitario presso la ASL di Corso V. Emanuele, a Mergellina.
                    <br />Sofia studia Economia alla Federico II e nel futuro si vede a lavorare presso una ONG internazionale, con l'ambizione di cambiare il mondo e le regole del gioco economico-politico. Uno dei suoi docenti, la professoressa Nica di Novastri, crede molto in lei e nella sua capacità  di fare una brillante carriera. Per questo motivo le ha affidato il compito di relazionarsi con le giovani matricole e con gli studenti delle superiori attratti dalla materia.
                    <br />Sofia ha alcune lievi idiosincrasie: non riesce a mangiare i funghi, ha il terrore delle blatte - e di tutti gli scarafaggi in generale - e a volte, appena sveglia, ha la sensazione di non riuscire più ad alzare la testa dal cuscino, come se il collo e la schiena fossero paralizzati.
                    <br />Sofia è gentile ed educata ed è sempre disponibile per gli altri; ma ci sono momenti in cui ha bisogno di restare sola con se stessa e durante le sue giornate si ritaglia più di un momento di solitudine.
                    <br />Nei suoi rapporti con gli uomini, Sofia è esigente e volubile al tempo stesso. Non è attratta dai ragazzi che si dimostrano superficiali e poco acculturati; per lei una storia con uno che non legge libri sarebbe impensabile. Ma Sofia vive delle contraddizioni, dovute al suo mettere la vita privata dietro alla sua formazione accademica, che ne fanno una ragazza che a volte scappa dalle relazioni con brutale rapidità  e franchezza.
                    <br />Sofia ama fare sport, anche se lo studio le concede sempre meno tempo per le attività  fisiche; da piccola ha fatto danza ed ora va a correre la mattina presto o la sera e a volte si concede una nuotata in piscina.
                    <br />I suoi genitori le hanno insegnato ad avere cura del suo corpo e a conservarlo integro; eppure, una volta che si sentiva sottosopra a causa di una relazione sentimentale finita male, si è fatta tatuare una minuscola cicatrice, cosa che spera i suoi non noteranno mai.</p>
                <button className={styles.close_button} onClick={onClose}>Chiudi</button></div>
        </div>
    );
};

export default Dialog;