/* import React from 'react';
import styles from './Layout.module.css';  // Assuming the same CSS file is used for styling

import paec from "../../assets/paec.png"
import tile from "../../assets/tile.jpg"
import cyber from "../../assets/cyberneid.base.black.png"

interface CreditsProps {
    show: boolean;
    onClose: () => void;
}

const Credits: React.FC<CreditsProps> = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className={styles.creditsOverlay}>
            <div className={styles.credits}>
                <div id="info-project" title="Sofia">
                    <center>
                        <h1>Credits</h1>
                        <br /><br />
                        <p>Università degli Studi di Napoli Federico II</p>
                        <p>Dipartimento di Scienze Economiche e Statistiche</p>

                        <img id="paec" src={paec} alt="Paec Logo" className={styles.paecLogo} />
                        <br /><br />
                        <p>
                            Finanziato dal MUR nell'ambito dei Piani di Orientamento e Tutorato (POT)
                        </p>
                        <div>
                            <h2>Gruppo di lavoro</h2>
                            <p>Cristina Davino, Roberto La Rovere, Stefano Marrone, Carlo Sansone.</p>
                        </div>
                        <p style={{ fontSize: '8pt' }}>
                            <a href="https://tilestorytellers.com" target="_blank" rel="noopener noreferrer">
                                <img id="tile-icon" src={tile} alt="Tile Icon" className={styles.tileLogo} />
                            </a>
                            &nbsp; &nbsp;&nbsp;&nbsp;
                            <a href="https://www.cyberneid.com" target="_blank" rel="noopener noreferrer">
                                <img id="cyberneid-icon" src={cyber} alt="Cyberneid Icon" className={styles.cyberLogo} />
                            </a>
                        </p>
                    </center>
                </div>
                <button onClick={onClose}>Chiudi</button>
            </div>
        </div>
    );
};

export default Credits; */

import React from 'react';
import styles from './Layout.module.css'; // Assuming the same CSS file is used for styling

import img1 from "../../assets/CreditsSofia1.0.png";
import img2 from "../../assets/CreditsSofia2.0.png";

interface CreditsProps {
    show: boolean;
    onClose: () => void;
}

const Credits: React.FC<CreditsProps> = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className={styles.creditsOverlay}>
            <div className={styles.credits}>
                <center>
                    <h1 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Credits</h1>
                    <p style={{ fontSize: '1em', fontStyle: 'italic' }}>Università degli Studi di Napoli Federico II - Dipartimento di Scienze Economiche e Statistiche</p>

                    <div style={{ margin: '0.5em 0' }}></div>

                    <h2 style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: '0.1em' }}>Sofia 2.0 (2024)</h2>
                    <img className={styles.responsiveImage} src={img2} alt="Sofia 2.0" />
                    {/* <img src={img2} alt="Sofia 2.0" style={{ maxWidth: '60%', height: 'auto' }} />*/}
                    <div style={{ margin: '0.25em 0' }}></div>

                    <h2 style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: '0.1em' }}>Sofia 1.0 (2021)</h2>
                    <img className={styles.responsiveImage} src={img1} alt="Sofia 1.0" />
                    {/* <img src={img1} alt="Sofia 1.0" style={{ maxWidth: '60%', height: 'auto' }} /> */}
                </center>
                <button className={styles.close_button} onClick={onClose}>Chiudi</button>
            </div>
        </div>
    );
};

export default Credits;